.navbar {
    background-color: #0c0c0c;
    position: sticky;
    top: 0;
    z-index: 1000;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    max-width: 70em;
    margin: auto;
}

.nav-brand {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.nav-menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    text-decoration: none;
}

.nav-link {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin: 1em;
    font-family: 'MuseoModerno';
    font-size: 1.2em;
}

.nav-link:hover {
    text-decoration: underline;
}

.language-switch {
    cursor: pointer;
}