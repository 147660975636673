.bot-spotlight {
    background-color: #090909;
    color: #eaeaea;
}

.bot-spotlight h1 {
    font-family: 'MuseoModerno';
    font-size: 3.7em;
    line-height: 1em;
}

.bot-spotlight p {
    font-size: 1.4em;
}

.bot-spotlight-info {
    padding: 0 3em;
    width: 80%;
}

.bot-spotlight .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.bot-spotlight-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #543bb1;
    color: #eaeaea;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 1.3em;
    padding: 0.5em 1em;
    margin: 1.5em 0;
    border: none;
    border-radius: 0.5em;
    margin-top: 1em;
    cursor: pointer;
    text-decoration: none;
    box-shadow: 0px 10px 30px -8px #543bb1;
}

.bot-spotlight-button:hover {
    text-decoration: underline;
}

.carousel {
    padding: 0.5em;
}

.bot-spotlight .carousel .slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52em;
}

.bot-spotlight .carousel .slide img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media (max-width: 768px) {
    .bot-spotlight.container {
        flex-direction: column;
    }

    .carousel {
        padding: 0 0 0.5em 0;
    }

    .bot-spotlight h1 {
        font-size: 2.7em;
    }

    .bot-spotlight-info {
        padding: 0;
        width: 100%;
    }
    
    .bot-spotlight .carousel .slide img {
        padding: 1em;
    }

    .bot-spotlight .carousel .slide {
        height: 25em;
    }
}