.footer {
    background-color: #0c0c0c;
    color: #eaeaea;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 2em;
}

.footer-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-left: 3em;
}

.footer a {
    color: #a8a8a8;
}

.footer a:hover {
    text-decoration: underline;
    color: #eaeaea;
}

.footer-socials {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    margin: 2em;
}

.footer-socials a {
    margin: 0 1em;
}

@media (max-width: 768px) {
    .footer {
        flex-direction: column;
        align-items: center;
        padding: 2em 0 1em 0;
    }

    .footer-links {
        margin-left: 0;
    }

    .footer-socials {
        margin-top: 1em;
        justify-content: center;
    }
}