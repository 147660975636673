.imprint {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    align-items: center;
    color: #eaeaea;
    padding-top: 100px;
    margin: 0 5px;
  }
  
  .imprint h1 {
    font-size: 4em;
    color:rgb(145, 145, 145);
  }
  
  .imprint h2 {
    color:rgb(202, 202, 202);
  }
  
  .imprint p {
    font-size: 1.5em;
    line-height: 1.4em;
  }
  
  .imprint-info {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .main {
    display: flex;
    flex-direction: column;
  }