.premier-spotlight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  align-items: center;
  background-size: inherit;
  background-position: center;
}

.premier-spotlight img {
  width: 6em;
  margin-top: 1.5em;
}

.premier-spotlight h1 {
  position: relative;
  color: #cda97f;
  text-transform: uppercase;
  font-family: 'MuseoModerno';
  font-weight: 600;
  font-size: 3.5em;
  line-height: 1em;
  z-index: 1;
  text-align: center;
  margin-bottom: 0.2em;
  margin-top: 0.4em;
  padding: 0 0.5em;
}

.premier-spotlight h1::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  filter: blur(0.2em);
  padding: 0 0.5em;
}

.premier-spotlight p {
  color: #f0edea;
  font-family: 'Inter';
  font-size: 1.5em;
  width: 65%;
  max-width: 35em;
  margin: auto;
  text-align: center;
}

.premier-spotlight-button {
  position: relative;
  background-color: #cda97f;
  color: #161615;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 1.3em;
  padding: 0.5em 1em;
  margin: 1.5em 0;
  border: none;
  border-radius: 0.5em;
  margin-top: 1em;
  cursor: pointer;
  text-decoration: none;
  box-shadow: 0px 10px 30px -8px #cda97f;
}

.premier-spotlight-button:hover {
  text-decoration: underline;
}