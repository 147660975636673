.lft-spotlight {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    text-align: left;
    color: #eaeaea;
    padding: 1em;
    position: relative;
}

.background {
    position: absolute;
    left: -2em;
    top: -2em;
    bottom: -2em;
    right: 0em;
    z-index: -1;
    background-position: center;
    background-size: 110%;
    background-position-y: 40%;
    background-position-x: -140%;
    background-repeat: no-repeat;
    background-color: #0C1220;
    filter: blur(0.1em);
    overflow: hidden;
}

.lft-spotlight h1 {
    position: relative;
    font-family: 'MuseoModerno';
    font-size: 3.5em;
    line-height: 1em;
    margin-top: 0.7em;
    margin-bottom: 0;
    color: #9198f8;
}

.lft-spotlight h1::before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    filter: blur(0.2em);
}

.lft-spotlight-info {
    padding: 0 3em;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.lft-spotlight p {
    width: 100%;
    max-width: 40em;
    font-size: 1.5em;
}

.lft-spotlight-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #9198f8;
    color: #0f0f0f;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 1.3em;
    padding: 0.5em 1em;
    margin-bottom: 1.5em;
    border-radius: 0.5em;
    cursor: pointer;
    text-decoration: none;
    box-shadow: 0px 10px 30px -8px #9198f8;
}

.lft-spotlight-button:hover {
    text-decoration: underline;
}

.lft-spotlight img {
    border-radius: 50%;
    width: 15em;
    height: 15em;
    margin-right: 3em;
    border: 3px solid #9198f8;
}

@media (max-width: 768px) {
    .lft-spotlight {
        flex-direction: column;
        text-align: center;
    }

    .lft-spotlight img {
        margin-right: 0;
        margin-bottom: 1em;
    }

    .lft-spotlight-info {
        padding: 1em;
        align-items: center;
    }

    .lft-spotlight h1 {
        font-size: 2.5em;
    }

    .lft-spotlight p {
        width: 100%;
    }

    .lft-spotlight-button {
        margin-top: 1em;
        flex-direction: row;
    }
}